var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12 md:col-12"},[_c('div',{staticClass:"card"},[_c('Panel',{staticClass:"mt-3",attrs:{"header":"Importar Arquivo"}},[_c('form',[_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{attrs:{"for":"tiposArquivos"}},[_vm._v("Tipo de Arquivo")]),_c('Dropdown',{class:{
                  'p-invalid': _vm.submitted && _vm.v$.tipo.$invalid,
                },attrs:{"id":"tiposArquivos","optionLabel":"label","options":_vm.filteredTiposArquivos,"filter":true,"filterPlaceholder":"Procure tipo","emptyFilterMessage":'Nenhum tipo encontrado',"placeholder":"Selecione um tipo"},model:{value:(_vm.v$.tipo.$model),callback:function ($$v) {_vm.$set(_vm.v$.tipo, "$model", $$v)},expression:"v$.tipo.$model"}}),(_vm.submitted && _vm.v$.tipo.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo tipo é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{attrs:{"for":"entidade"}},[_vm._v("Entidade")]),_c('Dropdown',{class:{
                  'p-invalid': _vm.submitted && _vm.v$.entidade.$invalid,
                },attrs:{"id":"entidade","optionLabel":"nome","options":_vm.entidades,"filter":true,"filterPlaceholder":"Procure pelo nome da entidade","emptyFilterMessage":'Nenhuma entidade encontrada',"placeholder":"Selecione uma entidade"},model:{value:(_vm.v$.entidade.$model),callback:function ($$v) {_vm.$set(_vm.v$.entidade, "$model", $$v)},expression:"v$.entidade.$model"}}),(_vm.submitted && _vm.v$.entidade.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo entidade é obrigatório. ")]):_vm._e()],1),(_vm.exibirComboConsignataria())?_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{attrs:{"for":"consignataria"}},[_vm._v("Consignatária")]),_c('Dropdown',{class:{
                  'p-invalid': _vm.submitted && _vm.v$.consignataria.$invalid,
                },attrs:{"id":"consignataria","data-key":"id","optionLabel":"razaoSocial","options":_vm.consignatarias,"filter":true,"filterPlaceholder":"Procure pelo nome da consignataria","emptyFilterMessage":'Nenhuma consignataria encontrada',"placeholder":"Selecione uma consignataria"},scopedSlots:_vm._u([{key:"value",fn:function(slotProps){return [(slotProps.value)?_c('div',[_c('span',[_vm._v(" "+_vm._s(slotProps.value.cnpj)+" | "+_vm._s(slotProps.value.razaoSocial)+" ")])]):_c('div',[_c('span',[_vm._v(_vm._s(slotProps.placeholder))])])]}},{key:"option",fn:function(slotProps){return [_c('div',[_c('span',[_vm._v(" "+_vm._s(slotProps.option.cnpj)+" | "+_vm._s(slotProps.option.razaoSocial)+" ")])])]}}],null,false,3281729119),model:{value:(_vm.v$.consignataria.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignataria, "$model", $$v)},expression:"v$.consignataria.$model"}}),(_vm.submitted && _vm.v$.consignataria.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo consignataria é obrigatório. ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{attrs:{"for":"anoReferencia"}},[_vm._v("Ano")]),_c('InputMask',{class:{
                  'p-invalid': _vm.submitted && _vm.v$.anoReferencia.$invalid,
                },attrs:{"id":"anoReferencia","mask":"9999"},model:{value:(_vm.v$.anoReferencia.$model),callback:function ($$v) {_vm.$set(_vm.v$.anoReferencia, "$model", $$v)},expression:"v$.anoReferencia.$model"}}),(_vm.submitted && _vm.v$.anoReferencia.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo ano é obrigatório ")]):_vm._e(),(_vm.submitted && _vm.v$.anoReferencia.validarAno.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo ano não pode ser menor do que o ano atual. Só é possível preencher o ano anterior ao ano atual, se o mês corrente for 01. ")]):_vm._e()],1),_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{attrs:{"for":"mesReferencia"}},[_vm._v("Mês (1 até 12)")]),_c('InputMask',{class:{
                  'p-invalid': _vm.submitted && _vm.v$.mesReferencia.$invalid,
                },attrs:{"id":"mesReferencia","mask":"99"},model:{value:(_vm.v$.mesReferencia.$model),callback:function ($$v) {_vm.$set(_vm.v$.mesReferencia, "$model", $$v)},expression:"v$.mesReferencia.$model"}}),(_vm.submitted && _vm.v$.mesReferencia.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo mês é obrigatório ")]):_vm._e(),(_vm.submitted && _vm.v$.mesReferencia.numeroPermitido.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo mês deve ser entre 01 e 12 ")]):_vm._e(),(_vm.submitted && _vm.v$.mesReferencia.validarMes.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo mês só pode ser preenchido entre o mês anterior e o mês atual ")]):_vm._e()],1)]),(_vm.uploading)?_c('ProgressBar',{staticStyle:{"height":"0.5em"},attrs:{"mode":"indeterminate"}}):_vm._e(),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12 md:col-12"},[_c('FileUpload',{ref:"fileUpload",attrs:{"id":"fileUpload","name":"file[]","accept":".text,.txt","maxFileSize":500000000,"customUpload":true,"chooseLabel":"Escolher arquivo","uploadLabel":"Enviar arquivo","showCancelButton":false,"invalidFileTypeMessage":'{0}: Tipo de arquivo inválido, tipos de arquivo permitidos: {1}',"invalidFileSizeMessage":'{0}: Tamanho de arquivo inválido, tamanho máximo permitido: {1}',"invalidFileLimitMessage":'{0}: Número máximo de arquivos excedido, número máximo de arquivos permitidos: {1}',"fileLimit":1},on:{"uploader":_vm.validate},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',[_vm._v("Selecione um arquivo para importar.")])]},proxy:true}])})],1)])],1)]),_c('Panel',{staticClass:"mt-3",attrs:{"header":"Arquivos Importados"}},[_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"paginator":true,"rows":5,"stripedRows":"","value":_vm.arquivos,"dataKey":"id","filters":_vm.filtros,"globalFilterFields":[
            'nomeArquivo',
            'mesReferencia',
            'anoReferencia',
            'statusProcessamento.mensagem',
            'entidade.nome',
            'tipo' ],"filterDisplay":"menu","paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":_vm.rowsPerPageOptions,"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} arquivos","responsiveLayout":"scroll"},on:{"update:filters":function($event){_vm.filtros=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhuma arquivo encontrado. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"flex flex-column sm:flex-row"},[_c('span',{staticClass:"p-input-icon-left mb-2 mr-2"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Pesquisar"},model:{value:(_vm.filtros['global'].value),callback:function ($$v) {_vm.$set(_vm.filtros['global'], "value", $$v)},expression:"filtros['global'].value"}})],1),_c('Button',{staticClass:"p-button-outlined mb-2",attrs:{"type":"button","icon":"pi pi-filter-slash","label":"Limpar"},on:{"click":_vm.limparFiltro}})],1)]},proxy:true}])},[_c('Column',{attrs:{"sortable":true,"field":"mesReferencia","header":"Mês/Ano"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var data = ref.data;
return [_vm._v(" "+_vm._s(data.mesReferencia || data.anoReferencia ? ('0' + data.mesReferencia).slice(-2) + '/' + data.anoReferencia : '-')+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"field":"tipo","header":"Tipo de Arquivo"}}),_c('Column',{attrs:{"sortable":true,"field":"nomeArquivo","header":"Nome"}}),_c('Column',{attrs:{"sortable":true,"field":"consignataria.razaoSocial","header":"Consignatária"}}),_c('Column',{attrs:{"sortable":true,"field":"entidade.nome","header":"Entidade"}}),_c('Column',{attrs:{"sortable":true,"field":"statusProcessamento.mensagem","header":"Status do Processamento"}}),_c('Column',{attrs:{"header":"Ações"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var data = ref.data;
return [_c('Button',{staticClass:"mr-2 mb-2 p-button-sucess",attrs:{"title":"Arquivo","label":"Arquivo","icon":"pi pi-download"},on:{"click":function($event){return _vm.baixarArquivo(data.nomeArquivo)}}}),(_vm.exibirBotaoRelatorioCritica(data))?_c('Button',{staticClass:"mr-2 mb-2 p-button-danger",attrs:{"title":"Arquivo Crítica","label":"Arquivo Crítica","icon":"pi pi-download"},on:{"click":function($event){return _vm.baixarRelatorioCritica(data)}}}):_vm._e(),(_vm.exibirBotaoSolicitarProcessmento(data))?_c('Button',{staticClass:"mr-2 mb-2 p-button-warning",attrs:{"disabled":data.processando},on:{"click":function($event){return _vm.confirmarSolicitarProcessamento(data)}}},[(data.processando)?_c('span',{staticClass:"pi pi-spin pi-spinner"}):_vm._e(),(!data.processando)?_c('span',{staticClass:"ml-2"},[_vm._v("Solicitar Processamento")]):_vm._e(),(data.processando)?_c('span',{staticClass:"ml-2"},[_vm._v("Aguarde")]):_vm._e()]):_vm._e()]}}])})],1)],1),_c('ModalConfirmarOperacao',{attrs:{"exibir":_vm.exibirModalConfirmarOperacao,"metodo":_vm.solicitarProcessamento,"msg":'Deseja solicitar o processamento?'},on:{"mudarVisibilidade":function($event){_vm.exibirModalConfirmarOperacao = $event}}}),_c('Dialog',{staticClass:"p-fluid",attrs:{"visible":_vm.acaoDialog,"header":"Tipo de Processamento","modal":true,"containerStyle":{ width: '500px' }},on:{"update:visible":function($event){_vm.acaoDialog=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-text",attrs:{"label":"Cancelar","icon":"pi pi-times"},on:{"click":_vm.fecharAcaoDialog}}),_c('Button',{attrs:{"label":"Solicitar","autofocus":""},on:{"click":_vm.solicitarProcessamento}})]},proxy:true}])},[_c('BlockUI',[_c('Card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12 md:col-12"},[_c('label',{attrs:{"for":"acao"}},[_vm._v("Ação")]),_c('Dropdown',{class:{
                      'p-invalid': _vm.v$.acao.$invalid,
                    },attrs:{"id":"acao","optionLabel":"label","options":_vm.carregarAcoes(),"emptyFilterMessage":'Nenhuma ação encontrada',"placeholder":"Selecione uma ação"},model:{value:(_vm.v$.acao.$model),callback:function ($$v) {_vm.$set(_vm.v$.acao, "$model", $$v)},expression:"v$.acao.$model"}}),(_vm.v$.acao.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo ação é obrigatório. ")]):_vm._e()],1)])]},proxy:true}])})],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }